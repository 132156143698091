@use '../../styles/variables.scss' as *;

button.button,
a.button {
  --button-border-radius: 16px;
  border-radius: var(--button-border-radius);
  font-family: MonzoSansText;
  font-weight: 600;
  text-align: center;
  padding: 16px 20px;
  border: none;

  &.primary:disabled,
  &.secondary:disabled,
  &.tertiary:disabled {
    background: $color-grey3;
    color: $color-grey2;
    border: none;

    &:active,
    &:hover,
    &:focus,
    &:focus-visible {
      background: $color-grey3;
    }
  }

  &.primary {
    border-radius: var(--button-border-radius);
    line-height: 21.6px;

    color: white;
    background: $color-mid-teal;

    &:focus {
      border: none;
    }

    &:hover,
    &:focus-visible {
      background: $color-deep-teal;
    }

    &:active {
      background: $color-dark-teal;
    }
  }

  &.secondary {
    border: 1px solid $color-mid-teal;
    background: #ffffff;
    color: $color-mid-teal;

    font-weight: 600;
    line-height: 20px;
    text-align: center;

    &:focus {
      border: 1px solid $color-mid-teal;
    }

    &:hover,
    &:focus-visible {
      background: $color-grey4;
    }

    &:active {
      color: $color-white;
      background: $color-deep-teal;
      border: 1px solid $color-mid-teal;
      &:focus {
        border: 1px solid $color-mid-teal;
      }
    }
  }

  &.tertiary {
    background: #c9d0c63d;
    color: $color-mid-teal;

    font-weight: 600;
    line-height: 20px;
    text-align: center;

    &:focus {
      border: none;
    }

    &:hover,
    &:focus-visible {
      background: $color-grey4;
      border: none;
    }

    &:active {
      color: $color-white;
      background: $color-deep-teal;
      &:focus {
        border: none;
      }
    }
  }

  &.small {
    font-size: 15px;
    height: 42px;
  }

  &.regular {
    font-size: 18px;
    height: 48px;
  }
}
