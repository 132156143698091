@use '../../styles/variables.scss' as *;

.error-banner-wrapper {
  width: 100%;
  text-align: center;
  background: #ff3b30;
  font-family: MonzoSansText;
  font-weight: 600;
  padding: 24px;
  font-size: 17px;
  color: #ffffff;
  line-height: 22px;
  z-index: 102;
}

.fatal-error-banner-wrapper {
  height: 100%;
  align-content: center;
  span {
    text-align: center;
    position: relative;
    top: 50%;
  }
}

.no-scroll {
  overflow: hidden;
}
