@use '../../styles/variables' as *;

.container {
  --font-stack-body: MonzoSansText;
  --font-weight-bold: 600;
  --font-weight-normal: 400;
  --primary-action-bg: #218fb7;
  --soft-white: #f2f8f3;
  --text-color-primary: #091723;

  display: grid;
  gap: 0px;
  grid-auto-flow: column;
  min-height: 100vh; // ensure footer background stretches to bottom of screen

  grid-template-areas:
    'header'
    'main'
    'aside'
    'copy-blocks'
    'logos'
    'download-cta'
    'footer';
  grid-template-rows:
    minmax($header-height, max-content) repeat(5, min-content)
    1fr;

  @media (min-width: $tablet-min) {
    grid-template-areas:
      'header'
      'main'
      'logos'
      'aside'
      'copy-blocks'
      'download-cta'
      'footer';
    grid-template-rows:
      minmax($header-height, max-content) repeat(5, min-content)
      1fr;
  }

  @media (min-width: $desktop-min) {
    grid-template-areas:
      'left-padding header-left header-right right-padding'
      'left-padding main aside right-padding'
      'left-padding main logos right-padding'
      '. copy-blocks download-cta .'
      'footer footer footer footer';
    grid-template-columns:
      1fr repeat(2, minmax($inner-content-width, $outer-content-width))
      1fr;
    grid-template-rows:
      minmax($header-height, max-content) repeat(3, min-content)
      1fr;
  }
}

.left-padding {
  background: var(--soft-white);
  display: none;
  margin-bottom: 16px;

  @media (min-width: $desktop-min) {
    display: block;
    grid-area: left-padding;
  }
}
.right-padding {
  display: none;

  @media (min-width: $desktop-min) {
    display: block;
    grid-area: right-padding;
  }
}

.error-banner {
  position: fixed;
}

.header-left {
  background: var(--soft-white);
  border-radius: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 32px;
  grid-area: header;
  justify-content: space-between;

  @media (min-width: $desktop-min) {
    border-top-right-radius: $border-radius-large;
    grid-area: header-left;
    .download-button {
      display: none;
    }
  }
}

.header-right {
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 0 32px;
  display: none;

  @media (min-width: $desktop-min) {
    display: flex;
    grid-area: header-right;
  }
}

.download-button {
  border-radius: var(--button-border-radius);
  background: #c9d0c63d;
  color: #218fb7;
  font-family: MonzoSansText;
  font-size: 15px;
  height: 42px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  &:hover {
    background: #218fb7;
    color: #ffffff;
  }
}

.main {
  align-items: center;
  align-self: stretch;
  background: var(--soft-white);
  border-bottom-left-radius: $border-radius-large;
  border-bottom-right-radius: $border-radius-large;
  grid-area: main;
  justify-content: center;
  padding: 8px 16px 32px;
  margin-bottom: 16px;

  @media (min-width: $desktop-min) {
    border-bottom-left-radius: 0;
    padding: 72px 0;
  }

  & > form {
    @media (min-width: $tablet-min) {
      margin: 0 auto;
    }
    @media (min-width: $desktop-min) {
      margin: 44px auto 147px;
    }
  }

  & > * {
    align-items: center;
    background: white;
    border-radius: 20px;
    box-shadow: 0px 2px 8px 0px #00000029;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: auto;
    max-width: 100%;
    padding: 24px;
    width: 363px;

    @media (min-width: $tablet-min) {
      margin: 0 auto 32px auto;
    }

    @media (min-width: $desktop-min) {
      margin: auto;
    }

    h1 {
      font-family: MonzoSansDisplay;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 0.01em;
      line-height: 34px;
    }

    p,
    label,
    button {
      font-family: var(--font-stack-body);
      font-size: 17px;
      line-height: 22px;
    }

    button {
      border-radius: var(--button-border-radius);
      width: 100%;
    }
  }
}
