@use '../../styles/variables.scss' as *;

.copy-blocks {
  grid-area: copy-blocks;
  justify-content: space-evenly;

  display: flex;
  flex-direction: column;
  border-radius: 40px;
  gap: 16px;
  padding: 32px;
  margin: 0 0 8px;
  --text-color-primary: #ffffff;
  --primary-action-text: #218fb7;
  --primary-action-bg: #ffffff;

  background: #ff4f40;

  &.aside {
    grid-area: aside;
    gap: 24px;
  }

  .smile {
    display: none;
  }

  a.learn-more {
    border: 1px solid transparent !important;
  }

  @media (min-width: $tablet-min) {
    --primary-action-text: #ffffff;
    --primary-action-bg: #218fb7;
    --text-color-primary: #091723;

    background: white;
    margin: 0 auto 48px;
    max-width: 594px;

    .smile {
      display: block;
    }

    a.learn-more {
      border: 1px solid $color-mid-teal !important;
    }
  }

  @media (min-width: $desktop-min) {
    margin: 16px auto;
    max-width: 554px;
    padding: 42px 24px;
  }

  & > img {
    max-width: fit-content;
  }

  & > h2 {
    font-family: OldschoolGrotesk;
    font-size: 36px;
    font-weight: 800;
    line-height: 39.6px;
    text-align: left;
  }
  & > p {
    font-family: MonzoSansText;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
  }

  a.learn-more {
    width: fit-content;
    text-align: center;
  }
}
