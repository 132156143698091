@use '../../styles/variables.scss' as *;

.aside {
  --text-color-primary: #091723;
  --font-stack-title: OldschoolGrotesk;
  --font-weight-bold: 800;

  align-items: stretch;
  align-self: center;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  grid-area: aside;
  justify-content: center;
  justify-self: center;
  position: relative;
  gap: 16px;
  text-align: left;
  background: white;
  margin: 0;
  padding: 32px;

  @media (min-width: $tablet-min) {
    --text-color-primary: #ffffff;
    background: #ff4f40;
    border-radius: $border-radius-large;
    padding: 76px;
    margin: 0 auto 48px;
    p {
      font-size: 20px;
      line-height: 28px;
    }

    a.join-monzo-button {
      border: 1px solid transparent;
    }
  }

  @media (min-width: $desktop-min) {
    background: white;
    border-radius: 0;
    padding: 0;
    gap: 24px;
    margin: 72px 0;
    max-width: 441px;
    --text-color-primary: #091723;

    a.join-monzo-button {
      border: 1px solid $color-mid-teal;
    }
  }

  .beach {
    max-width: 100%;
    border-radius: 20px;
    height: 208px;
    overflow: clip;
    background-image: url('/assets/couple-on-beach.jpg');
    background-size: cover;
    background-position: center center;

    display: none;
    @media (min-width: $tablet-min) {
      display: flex;
      h2 {
        line-height: 48px;
      }
      p {
        line-height: 28px;
      }
    }
    @media (min-width: $desktop-min) {
      max-width: 441px;
    }
  }
  h2 {
    line-height: 39.6px;
    font-size: 36px;
    font-weight: 800;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    margin: 0;
  }
}
