.profile {
  padding: 24px;
  text-align: center;

  p {
    margin: 0;
  }

  &-name {
    font-weight: 600;
    font-size: 18px;
    line-height: normal;
  }
}
