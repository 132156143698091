@use '../../styles/variables.scss' as *;

.download-cta {
  --text-color-primary: #000000;
  --font-stack-title: OldschoolGrotesk;
  --font-weight-bold: 800;
  align-items: center;
  background: $color-soft-white;
  border-radius: $border-radius-large;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-area: download-cta;
  justify-content: center;
  margin-bottom: 48px;
  padding: 48px 32px;
  font-size: 36px;
  line-height: 39.6px;

  @media (min-width: $desktop-min) {
    display: flex;
    background: #ff4f40;
    margin: 32px auto;
    padding: 48px;
    width: fit-content;
    height: fit-content;
    align-self: center;

    & > h3 {
      color: white;
      font-size: 36px;
      font-weight: 800;
      line-height: 39.6px;
    }
  }
}

.app-store-buttons {
  .download-link {
    .hidden-label {
      display: none;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      border: 0;
      top: 0;
      white-space: nowrap;
      word-wrap: normal;
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;

  // QR code
  & > div {
    display: none;
    @media (min-width: $tablet-min) {
      display: block;
    }
    & > div {
      height: 92px;
    }
    padding: 10px;
    border-radius: 10px;
    aspect-ratio: 1;
  }

  // App store button
  & > a {
    height: 45px;
    & > img {
      height: 45px;
    }
  }

  & > img {
    display: none;
    @media (min-width: $tablet-min) {
      display: block;
    }
  }
}
