/* New variables (#tmp-creative-refresh) */

// Hot Coral
$color-hot-coral: #ff4b44;

// Black and Navy
// - Our Darkest Colours
$color-black: #000000;
$color-navy1: #091723;
$color-navy2: #112231;
$color-navy3: #2c3a43;
$color-navy4: #3b4c54;

// Greys and Whites
// - Our greys and whites
$color-grey1: #75817e;
$color-grey2: #aeb5a7;
$color-grey3: #c9d0c6;
$color-grey4: #e3ebe4;
$color-grey5: #dddddd;

$color-soft-white: #f2f8f3;
$color-white: #ffffff;
$color-soft-platinum: #f2f6f7;

// Core UI Colors
// - Action Colours and Traffic Lights
$color-action-blue: #218fb7;

$color-red1: #c9390c;
$color-red2: #f5430b;

$color-yellow1: #d26e28;
$color-yellow2: #ffc328;

$color-green1: #34c759;
$color-green2: #30d158;

$color-platinum1: #768f95;
$color-platinum2: #95a8ac;
$color-platinum3: #d9e4e7;

// Accent Colors
$color-deep-orange: #ed5e11;
$color-mid-orange: #fe6b1b;
$color-pale-orange: #ff945a;

$color-deep-pink: #f365bf;
$color-mid-pink: #ffa9df;
$color-pale-pink: #ffd7f0;

$color-deep-neon: #a3dc1a;
$color-mid-neon: #c3ff34;
$color-pale-neon: #d9ff6f;

$color-deep-teal: #004e60;
$color-mid-teal: #016b83;
$color-pale-teal: #2eafc0;
$color-dark-teal: #072127;
$color-dark-teal2: #072d36;
$color-dark-teal3: #043540;
$color-dark-teal4: #284950;

$color-deep-blue: #68cdb5;
$color-mid-blue: #8ce6d1;
$color-pale-blue: #bbf6e2;

$color-deep-khaki: #3a4920;
$color-mid-khaki: #61763d;
$color-pale-khaki: #99af73;

/* Old colour variables */
$color-transparent: transparent;

$color-grey200: #f7f7f7;
$color-grey300: #e1e3e7;
$color-grey400: #c3c8cf;
$color-grey500: #707b8c;
$color-grey600: #4d5870;
$color-grey700: #34495e;
$color-grey800: #14233c;

$color-sky100: #2360c5;
$color-sky500: #2991cc;
$color-sky400: lighten($color-sky500, 20%) !default;
$color-sky600: darken($color-sky500, 20%) !default;

$color-light-blue: #2991cc0d;

$color-red500: #dd3524;
$color-red400: lighten($color-red500, 20%) !default;

$color-tangerine500: #f5a623;

$color-grass500: #00ba4e;
$color-grass600: darken($color-grass500, 5%) !default;
$color-grass700: darken($color-grass500, 10%) !default;

$color-violet400: #d0aefc;
$color-violet500: #b982ff;
$color-violet600: #8969b4;

// Gradients
$color-gradient-crimson: linear-gradient(45deg, #a942e4 0%, #f61818 100%);
$color-gradient-purple: linear-gradient(45deg, #6a42e4 0%, #f618ac 100%);
$color-gradient-lavender: linear-gradient(45deg, #4286e4 0%, #ac18f6 100%);
$color-gradient-sky: linear-gradient(45deg, #42e4d7 0%, #1818f6 100%);
$color-gradient-sea: linear-gradient(45deg, #42e46a 0%, #18acf6 100%);
$color-gradient-pear: linear-gradient(45deg, #e4d742 0%, #18f618 100%);
$color-gradient-sand: linear-gradient(45deg, #e46a42 0%, #f6f318 100%);
$color-gradient-carrot: linear-gradient(45deg, #e44242 0%, #f6ac18 100%);

// Background gradients
$color-gradient-navy: linear-gradient(135deg, #405b88 0%, $color-grey800 100%);

$colors: (
  black: $color-black,
  white: $color-white,
  transparent: $color-transparent,
  hot-coral: $color-hot-coral,
  grey200: $color-grey200,
  grey300: $color-grey300,
  grey400: $color-grey400,
  grey500: $color-grey500,
  grey600: $color-grey600,
  grey700: $color-grey700,
  grey800: $color-grey800,
  sky100: $color-sky100,
  sky500: $color-sky500,
  sky400: $color-sky400,
  sky600: $color-sky600,
  red500: $color-red500,
  light-blue: $color-light-blue,
  tangerine500: $color-tangerine500,
  grass500: $color-grass500,
  grass600: $color-grass600,
  grass700: $color-grass700,
  violet400: $color-violet400,
  violet500: $color-violet500,
  violet600: $color-violet600,
  gradient-crimson: $color-gradient-crimson,
  gradient-purple: $color-gradient-purple,
  gradient-lavender: $color-gradient-lavender,
  gradient-sky: $color-gradient-sky,
  gradient-sea: $color-gradient-sea,
  gradient-pear: $color-gradient-pear,
  gradient-sand: $color-gradient-sand,
  gradient-carrot: $color-gradient-carrot,
  // New colours
  navy1: $color-navy1,
  navy2: $color-navy2,
  navy3: $color-navy3,
  navy4: $color-navy4,
  grey1: $color-grey1,
  grey2: $color-grey2,
  grey3: $color-grey3,
  grey4: $color-grey4,
  grey5: $color-grey5,
  soft-white: $color-soft-white,
  action-blue: $color-action-blue,
  red1: $color-red1,
  red2: $color-red2,
  yellow1: $color-yellow1,
  yellow2: $color-yellow2,
  green1: $color-green1,
  green2: $color-green2,
  deep-orange: $color-deep-orange,
  mid-orange: $color-mid-orange,
  pale-orange: $color-pale-orange,
  deep-pink: $color-deep-pink,
  mid-pink: $color-mid-pink,
  pale-pink: $color-pale-pink,
  deep-neon: $color-deep-neon,
  mid-neon: $color-mid-neon,
  pale-neon: $color-pale-neon,
  deep-teal: $color-deep-teal,
  mid-teal: $color-mid-teal,
  pale-teal: $color-pale-teal,
  deep-blue: $color-deep-blue,
  mid-blue: $color-mid-blue,
  pale-blue: $color-pale-blue,
  deep-khaki: $color-deep-khaki,
  mid-khaki: $color-mid-khaki,
  pale-khaki: $color-pale-khaki,
);
