.container {
  display: grid;
  padding-bottom: 12px;
  width: 100%;
  max-width: 100%;
  gap: 16px;
  grid-template-columns: min-content 1fr min-content;
  border-bottom: 0.5px solid #3b4c5433;

  .text {
    display: grid;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-content: center;
    margin: 10px 0;
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: 100%;
  }

  > div {
    &:has(> img) {
      height: 36px;
      width: 36px;
      margin: 0;
      & > div {
        margin: 16px 12px 16px 0;
        font-size: 14px;
        line-height: 36px;
      }
    }
    > img {
      margin: 16px 12px 16px 0;
      display: block;

      &,
      & > * {
        height: 36px;
        width: 36px;
      }
    }
  }
}

p.name {
  font-family: MonzoSansDisplay;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 0;
}

p.note {
  color: #09172399;
  font-family: MonzoSansText;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  margin-top: 4px;
}

.amount {
  margin: 16px 0;

  & > * {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    letter-spacing: 0px;
  }

  .major {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .minor,
  .symbol {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}
