.container {
  height: fit-content;
  position: relative;
  padding: 24px;
  border-radius: 24px;
  overflow: hidden;
  background: #ffffff; // default to a white background if no theme is provided
  &.light {
    background: #ffffff;
  }
  &.dark {
    background: #000000;
  }
  .logoM {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 20%;
    margin: auto;
  }
}
.mask {
  -webkit-mask-image: radial-gradient(
    circle at 50% 50%,
    transparent 16%,
    black 16%
  );
  mask-image: radial-gradient(circle at 50% 50%, transparent 16%, black 16%);
}
