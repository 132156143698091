@forward '@mondough/monzo-ui/src/tokens/globals';

$border-radius-large: 40px;
$border-radius-small: 10px;

$header-height: 80px;
$inner-content-width: 569px;
$outer-content-width: 720px;

$desktop-min: 1138px;
$tablet-min: 745px;

$screen-xs: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// Accessible colours:
$color-mid-text: #6b7686;
$color-link: #2991cc;
$color-h2-text: #14233c; // unchanged

$color-soft-white: #f2f8f3;
$color-navy-1: #091723;
$color-navy-2: #2c3a43;
