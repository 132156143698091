@use '../../styles/variables' as *;

.header-left {
  background: var(--soft-white);
  border-radius: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 32px;
  grid-area: header;
  justify-content: space-between;

  @media (min-width: $desktop-min) {
    border-top-right-radius: $border-radius-large;
    grid-area: header-left;
    .download-button {
      display: none;
    }
  }
}

.header-right {
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  padding: 0 32px;
  display: none;

  @media (min-width: $desktop-min) {
    display: flex;
    grid-area: header-right;
  }
}
