@use '../../styles/variables' as *;

.footer {
  padding: 32px 16px;
  grid-area: footer;
  background: var(--text-color-primary);
  border-radius: 20px 20px 0 0;

  width: 100%;
  background-color: $color-grey800;
  padding: $m * 2 0;

  @include mq($from: tablet) {
    padding: $l * 2 0;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;

  & p {
    margin: 0 24px 16px 0;
  }
}

.inner {
  margin: 0 auto;
  padding: 0 $l;
  width: 100%;
  max-width: rem(1376);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($from: tablet) {
    flex-direction: row;
    padding: 0 $xl;
  }
}

.main {
  flex: 1;
  max-width: rem(620);

  p {
    font-size: rem(15);
  }

  a {
    color: var(--text-color-inverted, $color-grey300);
    text-decoration: underline;
  }
}
