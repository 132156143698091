$desktop-min: 1138px;
$tablet-min: 745px;

.logos {
  grid-area: logos;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: fit-content;
  gap: 18px;
  margin: 32px auto 40px;

  .logo {
    &-bba {
      height: 51px;
    }
    &-cass {
      height: 40px;
    }
    &-fscs {
      height: 48px;
    }
  }
}
